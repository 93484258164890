import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomePaginate from "vue-awesome-paginate"
import { createHead } from '@vueuse/head';
import './index.css'

createApp(App)
  .use(router)
  .use(VueAwesomePaginate)
  .use(createHead())
  .mount('#app')
