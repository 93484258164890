<template>
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-5 py-5 lg:py-10">
    <img :src="product.preview" :alt="product.name" @click="openLightbox(0)" class="cursor-pointer">

    <div class="flex flex-col gap-1 lg:gap-5 col-span-2">
      <router-link :to="{ path: `/products/${product.id}` }" class="text-xl lg:text-3xl">{{ product.name }}</router-link>
      <div class="flex gap-2 text-right text-xs text-blue uppercase">
        <p v-for="group in product.groups" :key="group" class="border p-2 border-blue">{{ group.name }}</p>
      </div>

      <button @click="toggleDescription"
        class="p-2 text-xs uppercase border w-full lg:w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
        {{ showDescription ? 'Скрыть характеристики' : 'Показать характеристики' }}
      </button>
      <div v-if="showDescription" v-html="product.description"></div>

      <ul class="list-inside list-disc">
        <li v-for="feature in product.features" :key="feature">{{ feature }}</li>
      </ul>
      <p class="text-xl lg:text-2xl text-blue font-medium">{{ formatPrice(product.price) }}</p>
      <button
        class="p-2 uppercase border w-full lg:w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all"
        @click="openModal">
        Купить
      </button>
    </div>

    <!-- Модальное окно для контактов -->
    <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="relative bg-white p-5 rounded-lg w-11/12 md:w-2/5">
        <!-- Кнопка для закрытия модального окна -->
        <button @click="closeModal" class="absolute top-0 right-2 text-blue text-2xl">&times;</button>

        <!-- Контент модального окна -->
        <h3 class="text-lg mb-4">Для заказа продукции позвоните или напишите нам!</h3>
        <p class="text-blue"><strong>Телефон:</strong> +7 (949) 501-33-35</p>
        <form class="mt-5 flex flex-col lg:grid lg:grid-cols-2 gap-5" @submit.prevent="submitForm">
          <div>
            <label for="name" class="mb-2 text-sm font-regular text-dark-gray sr-only">Ваше имя</label>
            <input v-model="form.name" id="name"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Ваше имя*" required>
          </div>
          <div>
            <label for="phone" class="mb-2 text-sm font-regular text-dark-gray sr-only">Номер телефона</label>
            <input v-model="form.phone" id="phone"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Номер телефона*" required>
          </div>
          <textarea v-model="form.question"
            class="h-20 lg:col-span-2 block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
            type="text" name="question" id="question" placeholder="Ваш вопрос" required></textarea>
          <button class="uppercase text-white bg-blue py-4 line px-9 text-sm content-center w-full max-h-[52px] lg:w-80"
            type="submit">
            Отправить
          </button>
          <div class="col-span-2" id="captcha-container"></div>
        </form>
      </div>
    </div>

    <!-- Модальное окно для просмотра изображений с элементами управления -->
    <div v-if="showLightbox" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      @keydown.esc="closeLightbox" @keydown.right="nextImage" @keydown.left="prevImage" tabindex="0">
      <div class="relative">
        <!-- Кнопка для закрытия модального окна -->
        <button @click="closeLightbox" class="absolute top-4 right-4 p-2 text-blue text-2xl z-10">&times;</button>

        <!-- Кнопка "Назад" -->
        <button @click="prevImage"
          class="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 text-blue text-3xl z-10">&#10094;</button>

        <!-- Кнопка "Вперёд" -->
        <button @click="nextImage"
          class="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 text-blue text-3xl z-10">&#10095;</button>

        <!-- Отображаемое изображение -->
        <img :src="product.photos[currentPhotoIndex]" class="max-w-full max-h-screen" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDescription: false,
      showLightbox: false,  // Контроль за модальным окном изображений
      showModal: false,      // Контроль за модальным окном контактов
      currentPhotoIndex: 0,  // Индекс текущего изображения
      form: {
        name: '',
        phone: '',
        question: ''
      },
      captchaToken: ''
    };
  },
  methods: {
    toggleDescription() {
      this.showDescription = !this.showDescription;
    },
    formatPrice(priceString) {
      const formattedIntegerPart = parseInt(priceString).toLocaleString();
      return `${formattedIntegerPart}₽`;
    },
    openLightbox(index) {
      this.currentPhotoIndex = index;
      this.showLightbox = true;
      this.$nextTick(() => {
        document.querySelector('.fixed').focus();  // Фокус на модальное окно для обработки клавиш
      });
    },
    closeLightbox() {
      this.showLightbox = false;
    },
    nextImage() {
      if (this.currentPhotoIndex < this.product.photos.length - 1) {
        this.currentPhotoIndex++;
      } else {
        this.currentPhotoIndex = 0;
      }
    },
    prevImage() {
      if (this.currentPhotoIndex > 0) {
        this.currentPhotoIndex--;
      } else {
        this.currentPhotoIndex = this.product.photos.length - 1;
      }
    },
    openModal() {
      this.showModal = true;
      this.$nextTick(() => {
        window.smartCaptcha.render('captcha-container', {
          sitekey: 'ysc1_Sr4GcXmxP1Z6pzps1Rpai6pLynLz6rUfdjY0P5f24b1f02cd',
          callback: this.onCaptchaVerified
        });
      });
    },
    closeModal() {
      this.showModal = false;
    },
    onCaptchaVerified(token) {
      this.captchaToken = token;  // Сохраняем токен капчи
    },
    async submitForm() {
      if (!this.captchaToken) {
        alert('Пожалуйста, пройдите проверку капчи!');
        return;
      }
      try {
        const response = await fetch('https://api.bazis.zk-digital.ru/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ ...this.form, captcha: this.captchaToken }),
        });
        const result = await response.json();
        if (result.success) {
          alert('Ваше сообщение отправлено!');
          this.form = {
            name: '',
            phone: '',
            question: ''
          }; // Очищаем поля формы
        } else {
          alert('Ошибка отправки, попробуйте позже.');
        }
      } catch (error) {
        console.error('Ошибка отправки формы:', error);
        alert('Произошла ошибка, попробуйте снова.');
      }
    }
  }
}
</script>

<style scoped></style>
