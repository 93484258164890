<template>
  <div class="bg-white">
    <div class="container px-5 mx-auto pt-5 lg:pt-12">
      <h1 class="text-2xl lg:text-5xl">Каталог продукции</h1>

      <div class="grid grid-cols-1 lg:grid-cols-3 py-3 gap-5 lg:gap-0 lg:py-12">
        <!-- Filters -->
        <div class="flex flex-col gap-5 lg:gap-5">
          <Listbox v-model="selectedOption">
            <div class="flex flex-col lg:flex-row gap-1 lg:gap-5 relative mb-5 lg:mb-0">
              <ListboxLabel class="flex text-sm lg:text-regular place-items-center"><span>Сортировка:</span>
              </ListboxLabel>
              <ListboxButton
                class="relative lg:w-64 border border-dark-gray cursor-default bg-white py-2 pl-3 pr-10 text-left shadow-md">
                <span class="block truncate">{{ selectedOption.name }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  class="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg">
                  <ListboxOption v-slot="{ active, selected }" v-for="option in options" :key="option.name"
                    :value="option" as="template" @click="updateProducts">
                    <li
                      :class="[active ? 'bg-light-blue text-blue' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                      <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option.name
                        }}</span>
                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>

          <!-- Categories -->
          <div v-for="category in categories" :key="category.id" class="flex flex-col gap-2 text-xs lg:text-lg">
            <div @click="toggleCategory(category)" class="cursor-pointer">
              <label :class="[
                selectedCategory === category.id ? 'bg-light-blue border border-blue text-blue' : 'text-gray-900',
                'relative cursor-pointer select-none p-2'
              ]">
                {{ category.name }}
              </label>
            </div>

            <!-- Subcategories -->
            <transition name="fade">
              <div v-if="isCategoryOpen(category.id)" class="ml-5 flex flex-col gap-2">
                <div v-for="subCategory in category.children" :key="subCategory.id" class="cursor-pointer"
                  @click="selectSubCategory(subCategory.id)">
                  <label :class="[
                    selectedCategory === subCategory.id ? 'bg-light-blue border border-blue text-blue' : 'text-gray-900',
                    'relative cursor-pointer select-none p-2'
                  ]">
                    {{ subCategory.name }}
                  </label>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div class="flex flex-col col-span-2 divide-y divide-dark-gray border-y border-dark-gray justify-top">
          <!-- Product item -->
          <p v-if="filteredProducts.length === 0" class="mx-auto">К сожалению, по заданным параметрам не удалось найти
            товары</p>
          <div v-for="product in filteredProducts" :key="product.id">
            <ProductItem :product="product" />
          </div>
        </div>

        <!-- Pagination -->
        <vue-awesome-paginate :total-items="totalItems" v-model="currentPage" :items-per-page="perPage"
          @click="pageChanged" :key="paginationKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { ref } from 'vue'
import {
  Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import axios from "axios";
import ProductItem from "@/views/ProductItem.vue";
import "vue-awesome-paginate/dist/style.css";

const sortOptions = [
  { name: 'по популярности', code: 'popularity' },
  { name: 'по возрастанию стоимости', code: 'price_asc' },
  { name: 'по убыванию стоимости', code: 'price_desc' }
]
const selectedSortOption = ref(sortOptions[0]);

export default {
  name: "ProductsView",
  components: {
    ProductItem,
    Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon
  },
  setup() {
    useHead({
      title: 'Центр технического обслуживания БАЗИС | Каталог товаров',
      meta: [
        { name: 'description', content: 'Продажа оборудования для автоматизации Вашего бизнеса' },
        { property: 'og:title', content: 'Центр технического обслуживания БАЗИС | Каталог товаров' },
        { property: 'og:description', content: 'Продажа оборудования для автоматизации Вашего бизнеса' },
        { property: 'og:image', content: 'https://cto-bazis.ru/img/logo.0b33d39f.svg' },
      ],
    });
  },
  data() {
    return {
      selectedOption: selectedSortOption,
      selectedCategory: null,
      openCategories: [], // Массив открытых категорий
      options: sortOptions,
      categories: [],
      products: [],
      totalItems: 0,
      perPage: 10,
      currentPage: 1,
      paginationKey: 1,
      searchQuery: '' // отслеживаем маршрутный параметр
    }
  },
  async mounted() {
    this.searchQuery = this.$route.query.query || '';
    const categoryId = parseInt(this.$route.query.category_id, 10);

    await this.fetchCategories();
    this.selectedCategory = categoryId || this.categories[0]?.id;

    await this.fetchProducts();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/categories');
        this.categories = response.data.categories || [];
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/products', {
          params: {
            sort_by: this.selectedOption.code,
            category_id: this.selectedCategory,
            page: this.currentPage,
            per_page: this.perPage,
            search: this.searchQuery
          }
        });
        this.products = response.data.data || [];
        this.totalItems = response.data.total || 0;
        this.paginationKey = Math.random();
        this.scrollToTop();
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
      }
    },
    async pageChanged(page) {
      this.currentPage = page;
      await this.fetchProducts();
    },
    async updateProducts() {
      await this.fetchProducts();
    },
    toggleCategory(category) {
      // Проверяем, есть ли у категории дочерние элементы
      if (category.children && category.children.length > 0) {
        // Открываем категорию или закрываем её
        if (this.openCategories.includes(category.id)) {
          this.openCategories = this.openCategories.filter(id => id !== category.id);
        } else {
          this.openCategories.push(category.id);
        }
      } else {
        // Если нет дочерних элементов, выбираем категорию и обновляем товары
        this.selectedCategory = category.id;
        this.updateProducts();
      }
    },
    isCategoryOpen(categoryId) {
      return this.openCategories.includes(categoryId);
    },
    selectSubCategory(subCategoryId) {
      this.selectedCategory = subCategoryId;
      this.updateProducts();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    filteredProducts() {
      return this.products;
    }
  }
}
</script>
